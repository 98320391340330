exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-2023-building-mdx": () => import("./../../../src/pages/posts/2023/building.mdx" /* webpackChunkName: "component---src-pages-posts-2023-building-mdx" */),
  "component---src-pages-vinterlytics-tsx": () => import("./../../../src/pages/vinterlytics.tsx" /* webpackChunkName: "component---src-pages-vinterlytics-tsx" */),
  "component---src-templates-post-layout-tsx-content-file-path-src-pages-posts-2023-building-mdx": () => import("./../../../src/templates/PostLayout.tsx?__contentFilePath=/opt/render/project/src/src/pages/posts/2023/building.mdx" /* webpackChunkName: "component---src-templates-post-layout-tsx-content-file-path-src-pages-posts-2023-building-mdx" */)
}

